//font size
//sp
$f_size_sp: 1.4rem;

//pc
$f_size_pc: 1.4rem;

//font family
@font-face {
  font-family: YuGothicM;
  src: local(Yu Gothic Medium),
  local(Yu Gothic);
  font-weight: 500;
}
$f_base: 'Noto Sans TC', YuGothicM, YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", "sans-serif";
$f_serif: 'Noto Serif JP', "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
$f_en: 'Montserrat', sans-serif;
// color scheme //
//brand colors
$c_bg: #fff;
$c_primary: #1B348A;
$c_primary_light: #E4E9F2;
$c_second: #FFFE55;
$c_accent: #FE7183;
$c_gray: #EFEFEF;
$c_text: #000;
$c_text_gray: #B4B4B4;

$c_error: #ED5454;

$c_sns_twitter: #1DA1F2;
$c_sns_facebook: #4267B2;

$c_border: #888;
$c_border_light: #BABABA;
$c_border_primary: rgba(27, 53, 138, 0.5);

$c_product_border: #707070;